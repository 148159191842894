<template>
  <div id="ActivityDetails">
    <div class="top-box">
      <p class="top-p">基本信息</p>
      <div class="top-nr">
        <div class="top-left">
          <img class="img" src="" alt="" />
          <p class="left-p">活动封面</p>
        </div>
        <div class="top-right">
          <div class="right-1">
            <p>活动标题：</p>
            <p style="margin: 29px 0">活动类型：</p>
            <p>活动类别：</p>
          </div>
          <div>
            <p>开始时间：</p>
            <p style="margin: 29px 0">结束时间：</p>
          </div>
        </div>
      </div>
    </div>
    <div class="nr-table">
      <p class="table-p">已加入机构</p>
      <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark">
        <el-table-column prop="name" label="id"> </el-table-column>
        <el-table-column prop="name" label="机构名称"> </el-table-column>
        <el-table-column prop="address" label="开始时间"></el-table-column>
        <el-table-column prop="address" label="结束时间"></el-table-column>
        <el-table-column prop="name" label="限量名额"> </el-table-column>
        <el-table-column prop="name" label="操作">
          <template>
            <samp style="color: #ff7e00">添加课程</samp>
            <samp style="color: #ff7e00; margin: 0 10px">编查看课程辑</samp>
            <samp style="color: #f56c6c">下架</samp>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普",
        },
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀",
        },
      ],
    };
  },
  created(){
    this.$axios({
      url:'/user/businessActivityType/queryManagerListPage',
      params:{
        id:45
      }
    })
  }
};
</script>

<style lang="less">
#ActivityDetails {
  color: #434343;
  .top-box {
    background-color: #ffffff;
    height: 240px;
    font-size: 16px;
    padding: 0 20px;
    .top-p {
      font-size: 18px;
      padding: 20px 0 29px 20px;
    }
    .top-nr {
      display: flex;
      .top-left {
        text-align: center;
        width: 180px;
        margin-right: 40px;
        .left-p {
          margin-top: 10px;
        }
        .img {
          width: 100px;
          height: 100px;
        }
      }
      .top-right {
        display: flex;
        .right-1 {
          width: 350px;
        }
      }
    }
  }
  .nr-table {
    padding: 0 20px;
    background-color: #ffffff;
    margin-top: 20px;
    .table-p {
      font-size: 18px;
      padding-top: 20px;
      margin-bottom: 30px;
    }
  }
}
.cell {
  text-align: center;
}
</style>
